import React, { useEffect, useState } from 'react';
import ContactForm from './ContactForm';
import Modal from './Modal';
import Notification from './Notification';

function IncludeList(props) {
    const tmp = props.items.map((item, index) => (
        <li key={index} className="flex items-start lg:col-span-1">
            <div className="flex-shrink-0">
                <svg className="h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor">
                <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                />
                </svg>
            </div>
            <p className="ml-3 text-sm leading-5 text-gray-700">{ item }</p>
        </li>
    ))
    return tmp
}

const PriceCard = ({data}) => {
    const {moms, price, title, description, include, formName, formDescription} = data;
    const [modalVisible, setModalVisible] = useState(false)
    const [notificationVisible, setNotificationVisible] = useState(false)

    console.log(title, description);
    const toggleModal = (sent = false) => {
        setModalVisible(false);
        if(sent) {
          setNotificationVisible(true)
        }
    }

    useEffect(() => {
        if(notificationVisible) {
          setTimeout(() => {
            setNotificationVisible(false)
          }, 5000);
        }
      }, [notificationVisible])

    return (
        <div className="mt-8 bg-white pb-16 sm:mt-12">
            <div className="relative">
                <div className="absolute inset-0 h-1/2"></div>
                    <div className="relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="max-w-lg mx-auto rounded-lg shadow-lg overflow-hidden lg:max-w-none lg:flex">
                            <div className="bg-white px-6 py-8 lg:flex-grow xl:p-12">
                                <h3 className="text-lg leading-8 font-extrabold text-gray-900 md:text-2xl sm:leading-9">{ title }</h3>
                                <p className="mt-6 text-base leading-6 text-gray-500">{ description }</p>
                                <div className="mt-8">
                                <div className="flex items-center">
                                    <h4
                                    className="flex-shrink-0 pr-4 bg-white text-sm leading-5 tracking-wider font-semibold uppercase text-green-600"
                                    >
                                    Vad ingår
                                    </h4>
                                    <div className="flex-1 border-t-2 border-gray-200"></div>
                                </div>
                                <ul className="mt-8 lg:grid lg:grid-cols-2 lg:col-gap-8 lg:row-gap-5">
                                    <IncludeList items={include}/>
                                </ul>
                            </div>
                        </div>
                        <div className="py-8 px-6 text-center lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center md:p-12">
                            <p className="text-lg leading-6 font-medium text-gray-900"></p>
                            <div className="mt-4 flex items-center justify-center text-5xl leading-none font-extrabold text-gray-900">
                                <span>{ price }</span>
                                <span className="ml-3 text-xl leading-7 font-medium text-gray-500">kr</span>
                            </div>
                            <p className="mt-4 text-sm leading-5">
                                {moms ? 'ink moms':'ex moms'}
                            </p>
                            <div className="mt-6">
                                <div className="">
                                    <button
                                    onClick={() => setModalVisible(true)}
                                    className="button fit primary"
                                    >Anmäl intresse
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal onClose={() => toggleModal(false)} isOpen={modalVisible}>
                <ContactForm formName="intresse-anmalan" subject={title} title={title} description={description} onClose={() => toggleModal(true)} />
            </Modal>
            {notificationVisible ? <Notification title="Tack för ditt intresse!" content="Vi återkommer så snart vi kan" /> : null}
        </div>
    );
};

export default PriceCard;