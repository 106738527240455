import React from 'react';
import { graphql } from 'gatsby';
import BlockContent, { getImageUrl } from '@sanity/block-content-to-react'
// import urlBuilder from '@sanity/image-url'
import BackgroundImage from 'gatsby-background-image'
import { sanityOptions } from '../config'
import Layout from '../components/Layout';
import PriceCard from '../components/PriceCard'
import HorseList from '../components/HorseList'
import Scroll from '../components/Scroll';
import { get } from 'lodash';

const serializers = {
  // types: {
  //   code: props => (
  //     <pre data-language={props.node.language}>
  //       <code>{props.node.code}</code>
  //     </pre>
  //   )
  // }
  list: props => {
    // console.log('LIST', props)
    return React.createElement('ul', { className: `list-disc pl-4`}, props.children)
    // return BlockContent.defaultSerializers.list(props)
  },
  types: {
    block: props => {
      const {style = 'normal'} = props.node;
      // console.log(props.node)
      if (style === "h2") {
        return React.createElement(style, { className: `clear-both text-base text-green font-semibold tracking-wide uppercase`}, props.children)
        // return <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">{props.children}</h2>
      }
      if (style === "h3") {
        return React.createElement(style, { className: `clear-both mt-8 mb-2 text-3xl text-green leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl`}, props.children)
        // return <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">{props.children}</h2>
      }
      
      if (style === 'blockquote') {
        return <blockquote>- {props.children}</blockquote>
      }
      
      // Fall back to default handling
      return BlockContent.defaultSerializers.types.block(props)
    },
    inlineimage: props => {
      // console.log('WOHOO', props)
      return (
        <img src={props.mark.asset.url} />
      )
    },
    figure: ({ node }) => {
      const { caption } = node;
      const url = getImageUrl({ node, options: sanityOptions })
      // return <img src={url} alt={caption} />
      return (
        <figure className="md:w-1/2 md:pr-12 pb-8 float-left">
          <img className="object-cover shadow-lg rounded-lg" src={url} alt={caption} />
          <figcaption className="text-sm font-medium text-gray-900 pl-2">{caption}</figcaption>
        </figure>
      )
    }
  }
}

export const query = graphql`
  query GetSection($id: String) {
    sanitySection(id: {eq: $id}) {
      id
      header,
      subheader
      description
      _rawContent
      pricecard {
        id
        moms
        price
        title
        description
        include
      }
      horse {
        id
        name
        mother
        sex
        subheader
        father
        description
        currently
        breed
        image {
          asset {
            fluid(maxWidth: 480) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      image {
        asset {
          fluid(maxWidth: 1920) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`



const SectionPage = (props) => {
  const { data = {}, errors } = props
  const pageData = get(data, 'sanitySection')
  // console.log('PageData',pageData)
  return (
    // <BackgroundImage
    //     Tag="section"
    //     className="background-image"
    //     fluid={pageData.background.asset.fluid}
    //     backgroundColor={`#040e18`}
    //     style={{}}
    // >
    <Layout fullMenu>
      <article id="main" className="sub-section">
        {/* <BackgroundImage
                  Tag="header"
                  className="background-image"
                  fluid={pageData.image.asset.fluid}
                  backgroundColor={`#040e18`}
                  style={{}}
                > */}
        <header className="section">
          <h2 className="text-white uppercase font-extrabold tracking-widest">{pageData.header}</h2>
          <p className="text-white" >{pageData.subheader}</p>
          <ul className="actions special">
            <li>
              <Scroll type="id" element="description">
                <a href="/#" className="button primary">
                  Läs mer
              </a>
              </Scroll>
            </li>
            {pageData.pricecard.length > 0 ? <li>
              <Scroll type="id" element="pricelist">
                <a href="/#" className="button ">
                  Prislista
              </a>
              </Scroll>
            </li> : null}
          </ul>
        </header>
        {/* </BackgroundImage> */}
        <section id="description" className="wrapper style5 bg-white flex flex-col">
          <p className="section-description mt-4 max-w-3xl mx-auto text-center text-xl">{pageData.description}</p>
          <div className="inner pt-8">
            <BlockContent blocks={pageData._rawContent} className="block-content flex flex-col md:block" serializers={serializers} />
          </div>
          <div id="pricelist">
            {pageData.pricecard.map((priceCard, index) => priceCard ? <PriceCard data={priceCard} key={index} /> : null )}
          </div>
          <div id="horselist">
            {/* {pageData.horse.map((horseCard, index) => priceCard ? <PriceCard data={priceCard} key={index} /> : null )} */}
            {pageData.horse.length > 0 ? <HorseList horses={pageData.horse} /> : null}
          </div>
        </section>
      </article>
    </Layout>
    // </BackgroundImage>
  )
};

export default SectionPage;
