import React from 'react';
import Img from "gatsby-image"
import { lowerCase } from 'lodash';
import { kebabCase } from 'lodash';

const HorseCard = ({horse} = {}) => {
    // console.log('WOOT', horse)
    const {name, breed, description, image} = horse;

    const lower = lowerCase(name)
    const sanitizedName = kebabCase(lower)
    const slug = `hastarna/${sanitizedName}`

    return (
        <li>
            {/* <a href={slug}> */}
                <div className="space-y-4">
                    <div className="relative pb-2/3">
                        {image ? <Img className="absolute object-cover h-full w-full shadow-lg rounded-lg" fluid={image.asset.fluid} alt="true" /> : null}
                    </div>
                    <div className="text-lg leading-6 font-medium space-y-1">
                        <h4 class="text-green">{ name }</h4>
                        <p className="">{ breed }</p>
                    </div>
                    <div className="text-lg leading-7">
                        <p className="text-gray-500">
                            { description }
                        </p>
                    </div>
                </div>
            {/* </a> */}
        </li>
    );
};

export default HorseCard;