import React from 'react';
import HorseCard from './HorseCard'

const HorseList = ({horses}) => {
    const currentlyOnTheFarm = horses.filter(horse => horse.currently)
    const notOnTheFarm = horses.filter(horse => !horse.currently)
    return (
        <div className="bg-white">
            <div className="mx-auto py-16 px-4 max-w-screen-xl sm:px-6 lg:px-8">
                <a id="information" />
                <div className="space-y-12 lg:grid lg:grid-cols-3 lg:gap-8 lg:space-y-0">
                    <div className="space-y-5 sm:space-y-4">
                    <h2 className="text-3xl text-green leading-9 font-extrabold tracking-tight sm:text-4xl">På anläggningen</h2>
                    <p className="text-xl leading-7 text-gray-500">
                        Våra nuvarnade hästar som står innakorderade eller är på in/tillridning.
                    </p>
                    </div>
                    <div className="lg:col-span-2">
                        <ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 md:gap-x-8">
                            {currentlyOnTheFarm.map(horse => <HorseCard horse={horse} key={horse.id} />)}
                        </ul>
                    </div>
                </div>
                </div>
                <div className="mx-auto py-12 px-4 max-w-screen-xl sm:px-6 lg:px-8 lg:py-24">
                <div className="space-y-12 lg:grid lg:grid-cols-3 lg:gap-8 lg:space-y-0">
                    <div className="space-y-5 sm:space-y-4">
                    <h2 className="text-3xl leading-9 font-extrabold tracking-tight text-green sm:text-4xl">Tidigare på anläggningen</h2>
                    <p className="text-xl leading-7 text-gray-500">
                        En gång på Lindgrens Hästcenter alltid på Lindgrens Hästcenter! Våra tidigare förmedlingshästar eller som varit
                        på in/tillridning.
                    </p>
                    </div>
                    <div className="lg:col-span-2">
                        <ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 md:gap-x-8">
                            {notOnTheFarm.map(horse => <HorseCard horse={horse} key={horse.id} />)}
                        </ul>
                    </div>
                </div>
                </div>
        </div>
    );
};

export default HorseList;